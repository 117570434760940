import fetch from '@/utils/fetch';
// For /vanzari/comenzi
export function getOrders(data) {
    return fetch({
        url: '/orders/getOrders',
        method: 'post',
        data: data,
    });
}

export function addExcludedOrder(data) {
    return fetch({
        url: '/orders/addExcludedOrder',
        method: 'post',
        data: data,
    });
}

export function removeExcludedOrder(data) {
    return fetch({
        url: '/orders/removeExcludedOrder',
        method: 'post',
        data: data,
    });
}

// For /vanzari/facturare
export function getBillingProducts() {
    return fetch({
        url: '/admininfo/getBillableProducts',
        method: 'post',
    });
}

export function saveBillingProducts(data) {
    return fetch({
        url: '/admininfo/billProducts',
        method: 'post',
        data: data,
    });
}

// For /vanzari/comanda
export function returProduct(data) {
    return fetch({
        url: '/orders/returProduct',
        method: 'post',
        data: data,
    });
}

export function getOrderDetails(data) {
    return fetch({
        url: '/orders/getOrder',
        method: 'post',
        data: data,
    });
}

export function getOrderStatusList() {
    return fetch({
        url: '/orders/getOrderStatusList',
        method: 'post',
    });
}

export function listStatuses() {
    return fetch({
        url: '/orders/listStatuses',
        method: 'post',
    });
}

export function checkPaymentForOrder(data) {
    return fetch({
        url: '/orders/checkPaymentForOrder',
        method: 'post',
        data: data,
    });
}

export function listAddresses() {
    return fetch({
        url: '/admininfo/listAddresses',
        method: 'post',
    });
}

export function addProductsToOrder(data) {
    return fetch({
        url: '/orders/addProductsToOrder',
        method: 'post',
        data: data,
    });
}

export function removeProductsFromOrder(data) {
    return fetch({
        url: '/orders/removeProductsFromOrder',
        method: 'post',
        data: data,
    });
}

export function saveOrderData(data) {
    return fetch({
        url: '/orders/saveOrderData',
        method: 'post',
        data: data,
    });
}

export function printInvoice(data) {
    return fetch({
        url: '/orders/printInvoice',
        method: 'post',
        data: data,
    });
}

export function generateInvoice(data) {
    return fetch({
        url: '/orders/generateInvoice',
        method: 'post',
        data: data,
    });
}

export function confirmOrder(data) {
    return fetch({
        url: '/orders/confirmOrder',
        method: 'post',
        data: data,
    });
}

export function billingDelete(data) {
    return fetch({
        url: '/orders/billingDelete',
        method: 'post',
        data: data,
    });
}

export function listCancellingStatuses() {
    return fetch({
        url: '/orders/listCancellingStatuses',
        method: 'post',
    });
}

export function cancelOrder(data) {
    return fetch({
        url: '/orders/cancelOrder',
        method: 'post',
        data: data,
    });
}

export function finishOrder(data) {
    return fetch({
        url: '/orders/finishOrder',
        method: 'post',
        data: data,
    });
}

export function importInvoice(data) {
    return fetch({
        url: '/orders/importInvoice',
        method: 'post',
        data: data,
    });
}

export function setReadyForDelivery(data) {
    return fetch({
        url: '/orders/setReadyForDelivery',
        method: 'post',
        data: data,
    });
}

export function reactivateOrder(data) {
    return fetch({
        url: '/orders/reactivateOrder',
        method: 'post',
        data: data,
    });
}

export function statusAwb(data) {
    return fetch({
        url: '/orders/statusAwb',
        method: 'post',
        data: data,
    });
}

export function importAwb(data) {
    return fetch({
        url: '/orders/importAwb',
        method: 'post',
        data: data,
    });
}

export function addOrderObservations(data) {
    return fetch({
        url: '/orders/addOrderObservations',
        method: 'post',
        data: data,
    });
}

export function removeInvoice(data) {
    return fetch({
        url: '/orders/removeInvoice',
        method: 'post',
        data: data,
    });
}

export function stornoOrder(data) {
    return fetch({
        url: '/orders/StornareComanda',
        method: 'post',
        data: data,
    });
}

export function changeDeliveryAddress(data) {
    return fetch({
        url: '/orders/editDeliveryAddress',
        method: 'post',
        data: data,
    });
}

export function changeOrderProductStatus(data) {
    return fetch({
        url: '/orders/changeOrderProductStatus',
        method: 'post',
        data: data,
    });
}

export function getPaymentMethods() {
    return fetch({
        url: '/orders/getPaymentMethods',
        method: 'post',
    });
}

export function changeOrderPaymentMethod(data) {
    return fetch({
        url: '/orders/changeOrderPaymentMethod',
        method: 'post',
        data: data,
    });
}

export function generateAwb(data) {
    return fetch({
        url: '/orders/generateAwb',
        method: 'post',
        data: data,
    });
}

export function getCouriersOptions(data) {
    return fetch({
        url: '/orders/getCouriersOptions',
        method: 'post',
        data: data,
    });
}

export function getCourierServices(data) {
    return fetch({
        url: '/orders/getCourierServices',
        method: 'post',
        data: data,
    });
}

export function printOrder(data) {
    return fetch({
        url: '/orders/printOrder',
        method: 'post',
        data: data,
    });
}

export function sendProforma(data) {
    return fetch({
        url: '/orders/sendProforma',
        method: 'post',
        data: data,
    });
}

// For vanzari/cupoane
export function saveCouponsCampaign(data) {
    return fetch({
        url: '/coupons/saveCouponsCampaign',
        method: 'post',
        data: data,
    });
}

export function getCouponCampaigns() {
    return fetch({
        url: '/coupons/getCouponCampaigns',
        method: 'post',
    });
}

export function toggleCampaignStatus(data) {
    return fetch({
        url: '/coupons/toggleCampaignStatus',
        method: 'post',
        data: data,
    });
}

export function getCouponsCampaignData(data) {
    return fetch({
        url: '/coupons/getCouponsCampaignData',
        method: 'post',
        data: data,
    });
}

export function getSupplierCategsBrandsAndProducts() {
    return fetch({
        url: '/coupons/getSupplierCategsBrandsAndProducts',
        method: 'post',
    });
}

export function updateCouponsCampaign(data) {
    return fetch({
        url: '/coupons/updateCouponsCampaign',
        method: 'post',
        data: data,
    });
}

export function deleteCampaignRestriction(data) {
    return fetch({
        url: '/coupons/deleteCampaignRestriction',
        method: 'post',
        data: data,
    });
}

export function updateCampaignRestrictions(data) {
    return fetch({
        url: '/coupons/updateCampaignRestrictions',
        method: 'post',
        data: data,
    });
}

export function toggleCouponStatus(data) {
    return fetch({
        url: '/coupons/toggleCouponStatus',
        method: 'post',
        data: data,
    });
}

export function saveCustomAmount(data) {
    return fetch({
        url: '/coupons/saveCustomAmount',
        method: 'post',
        data: data,
    });
}

export function deleteCustomAmount(data) {
    return fetch({
        url: '/coupons/deleteCustomAmount',
        method: 'post',
        data: data,
    });
}

export function saveCustomExpire(data) {
    return fetch({
        url: '/coupons/saveCustomExpire',
        method: 'post',
        data: data,
    });
}

export function deleteCustomExpire(data) {
    return fetch({
        url: '/coupons/deleteCustomExpire',
        method: 'post',
        data: data,
    });
}

export function generateCoupons(data) {
    return fetch({
        url: '/coupons/generateCoupons',
        method: 'post',
        data: data,
    });
}

export function finishOrderCombined(data) {
    return fetch({
        url: '/orders/finishOrderCombined',
        method: 'post',
        data: data,
    });
}

export function addTransportTax(data) {
    return fetch({
        url: '/orders/addTransportTax',
        method: 'post',
        data: data,
    });
}

export function addWeightToOrder(data) {
    return fetch({
        url: '/orders/addWeightToOrder',
        method: 'post',
        data: data,
    });
}

export function changeBillableAddress(data) {
    return fetch({
        url: '/orders/changeBillableAddress',
        method: 'post',
        data: data,
    });
}

export function changePickupPoint(data) {
    return fetch({
        url: '/orders/changePickupPoint',
        method: 'post',
        data: data,
    });
}

export function printAwb(data) {
    return fetch({
        url: '/orders/printAwb',
        method: 'post',
        data: data,
    });
}

export function banClient(data) {
    return fetch({
        url: '/orders/banClient',
        method: 'post',
        data: data,
    });
}

export function rejectCancelRequest(data) {
    return fetch({
        url: '/orders/rejectCancelRequest',
        method: 'post',
        data: data,
    });
}

export function downloadInvoice(data) {
    return fetch({
        url: '/orders/downloadInvoice',
        method: 'post',
        data: data,
    });
}

export function downloadProforma(data) {
    return fetch({
        url: '/orders/downloadProforma',
        method: 'post',
        data: data,
    });
}

export function generateSmartBillProforma(data) {
    return fetch({
        url: '/orders/generateSmartBillProforma',
        method: 'post',
        data: data,
    });
}

export function downloadSmartBillProforma(data) {
    return fetch({
        url: '/orders/downloadSmartBillProforma',
        method: 'post',
        data: data,
    });
}

export function deleteSmartBillProforma(data) {
    return fetch({
        url: '/orders/deleteSmartBillProforma',
        method: 'post',
        data: data,
    });
}

export function hasSmartBill(data) {
    return fetch({
        url: '/orders/hasSmartBill',
        method: 'post',
        data: data,
    });
}

export function changeFinishingDate(data) {
    return fetch({
        url: '/orders/changeFinishingDate',
        method: 'post',
        data: data,
    });
}

export function makeOrderDisputed(data) {
    return fetch({
        url: '/orders/makeOrderDisputed',
        method: 'post',
        data: data,
    });
}

export function generateDemoOrder(data) {
    return fetch({
        url: '/orders/generateDemoOrder',
        method: 'post',
        data: data,
    });
}

export function getCouriers(data) {
    return fetch({
        url: '/orders/getCouriers',
        method: 'post',
        data,
    });
}

export function getCourierInfo(data) {
    return fetch({
        url: '/orders/getCourierInfo',
        method: 'post',
        data,
    });
}

export function getOrderAwbs(data) {
    return fetch({
        url: '/orders/getOrderAwbs',
        method: 'post',
        data,
    });
}

export function downloadAwb(data) {
    return fetch({
        url: '/orders/downloadAwb',
        method: 'post',
        data,
    });
}

export function getPickupAddresses(data) {
    return fetch({
        url: '/orders/getPickupAddresses',
        method: 'post',
        data,
    });
}

export function getPickupPoints() {
    return fetch({
        url: '/orders/getPickupPoints',
        method: 'post',
    });
}

export function getOrderAwbsHistory(data) {
    return fetch({
        url: '/orders/getOrderAwbsHistory',
        method: 'post',
        data,
    });
}
export function getOrderInvoices(data) {
    return fetch({
        url: '/orders/getOrderInvoices',
        method: 'post',
        data,
    });
}

export function addOrderInvoice(data) {
    return fetch({
        url: '/orders/addOrderInvoice',
        method: 'post',
        data,
    });
}
export function deleteOrderInvoice(data) {
    return fetch({
        url: '/orders/deleteOrderInvoice',
        method: 'post',
        data,
    });
}
export function downloadOrderInvoice(data) {
    return fetch({
        url: '/orders/downloadOrderInvoice',
        method: 'post',
        data,
    });
}

export function getOrderConversations(data) {
    return fetch({
        url: '/orders/getOrderConversations',
        method: 'post',
        data,
    });
}

export function getCourierLockers(data) {
    return fetch({
        url: '/orders/getCourierLockers',
        method: 'post',
        data,
    });
}

export function getLockersRegions(data) {
    return fetch({
        url: '/orders/getLockersRegions',
        method: 'post',
        data,
    });
}

export function getLockerDetails(data) {
    return fetch({
        url: '/orders/getLockerDetails',
        method: 'post',
        data,
    });
}
