<template>
	<div class="app-container">
		<el-button type="primary" class="fullWidth" size="small" icon="el-icon-plus" v-if="!cardOpened"
			@click="cardOpened = true">{{$t('sells.coupons.add-campaign')}}</el-button>

		<el-card class="box-card campaign-add-card" v-if="cardOpened">
			<div class="separator"></div>
			<el-form :model="campaign" :rules="rules" ref="campaign">
				<div>
					<div class="sub-title">{{$t('sells.coupons.campaign-name')}}</div>
					<el-form-item prop="name">
						<el-input type="text" size="small" v-model="campaign.name"></el-input>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.coupons.campaign-desc')}}</div>
					<el-form-item prop="description">
						<el-input type="textarea" size="small" v-model="campaign.description"></el-input>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.coupons.reduction-type')}}</div>
					<el-form-item prop="discountType">
						<el-select size="small" v-model="campaign.discountType" class="fullWidth"
							:placeholder="$t('sells.coupons.ph-1')" @change="changeCouponType">
							<el-option v-for="item in discountType" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.coupons.coupon-value')}} ({{coupon.type}})</div>
					<el-form-item prop="value">
						<el-input-number class="fullWidth" size="small" :min="coupon.min" :max="coupon.max" type="text"
							v-model="campaign.value"></el-input-number>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.coupons.min-order')}} (RON)</div>
					<el-form-item prop="minOrder">
						<el-input-number class="fullWidth" size="small" :min="1" type="text"
							v-model="campaign.minOrder"></el-input-number>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.coupons.uses-total')}}</div>
					<el-form-item prop="totalUtilize">
						<el-input-number class="fullWidth" size="small" :min="0" type="text"
							v-model="campaign.totalUtilize"></el-input-number>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.coupons.uses-user')}}</div>
					<el-form-item prop="userUtilize">
						<el-input-number class="fullWidth" size="small" :min="0" type="text"
							v-model="campaign.userUtilize"></el-input-number>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.coupons.date-start')}}</div>
					<el-form-item prop="dateStart">
						<el-date-picker size="small" class="fullWidth" v-model="campaign.dateStart"
							value-format="yyyy-MM-dd" type="date">
						</el-date-picker>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.coupons.date-end')}}</div>
					<el-form-item prop="dateEnd">
						<el-date-picker size="small" class="fullWidth" v-model="campaign.dateEnd"
							value-format="yyyy-MM-dd" type="date">
						</el-date-picker>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.coupons.domain-res')}}</div>
					<el-form-item prop="domainRestriction">
						<el-input size="small" type="text" v-model="campaign.domainRestriction"></el-input>
					</el-form-item>
				</div>
				<div>
					<div class="sub-title">{{$t('sells.coupons.product-res')}}</div>
					<el-form-item prop="productRestrictions">
						<el-checkbox-group v-model="campaign.productRestrictions" size="small">
							<el-checkbox-button v-for="p in productType" :label="p.value" :key="p.label">{{p.label}}
							</el-checkbox-button>
						</el-checkbox-group>
					</el-form-item>
				</div>
				<el-button type="success" class="fullWidth" size="small" icon="el-icon-plus" @click="saveCampaign">
					{{$t('sells.coupons.add-campaign-2')}}</el-button>
			</el-form>
		</el-card>
		<div class="separator"></div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>{{$t('sells.coupons.header-1')}}</span>
			</div>
			<template v-if="campaignsList.length > 0">
				<el-table :data="campaignsList" style="width: 100%" size="small">
					<el-table-column prop="id" label="ID" width="50px">
					</el-table-column>
					<el-table-column prop="name" :label="$t('general.name')">
					</el-table-column>
					<el-table-column prop="amount" :label="$t('sells.coupons.amount')" width="140px">
					</el-table-column>
					<el-table-column prop="type" :label="$t('sells.coupons.type')" width="100px">
						<template slot-scope="scope">
							{{discountTypeDisplay(scope.row.type)}}
						</template>
					</el-table-column>
					<el-table-column prop="expire_date" :label="$t('sells.coupons.expire-date')" width="140px">
					</el-table-column>
					<el-table-column prop="minimum_order" :label="$t('sells.coupons.min-order')" width="140px">
					</el-table-column>
					<el-table-column prop="type" :label="$t('sells.coupons.discount-type')" width="100px">
						<template slot-scope="scope">
							{{discountTypeDisplay(scope.row.type)}}
						</template>
					</el-table-column>
					<el-table-column :label="$t('sells.coupons.validity')" width="100px">
						<template slot-scope="scope">
							<el-tag class="campaign-tag" size="small"
								:type="!olderThanToday(scope.row.expire_date) ? 'success' : 'error'">
								{{statusDisplay(scope.row.expire_date)}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="active" :label="$t('sells.coupons.active')" width="100px">
						<template slot-scope="scope">
							<el-switch v-model="scope.row.active" active-value="Y" inactive-value="N"
								@change="toggleCampaignStatus(scope.row.id)">
							</el-switch>
						</template>
					</el-table-column>
					<el-table-column prop="" :label="$t('general.actions')" width="100px">
						<template slot-scope="scope">
							<router-link :to="{path: '/vanzari/campanie/' + scope.row.id}">
								<el-button type="primary" size="mini" plain>{{$t('general.details')}}</el-button>
							</router-link>
						</template>
					</el-table-column>
				</el-table>
			</template>
			<template v-else>
				<el-alert :closable="false" :title="$t('general.warning')" type="warning"
					:description="$t('sells.coupons.warning')" show-icon>
				</el-alert>
			</template>
		</el-card>
	</div>
</template>

<script>
	import {
		saveCouponsCampaign,
		getCouponCampaigns,
		toggleCampaignStatus
	} from '@/api/vanzari';

	import has from 'lodash.has';
	export default {
		data() {
			return {
				cardOpened: false,
				campaign: {
					name: '',
					description: '',
					discountType: '',
					value: '',
					minOrder: '',
					totalUtilize: '',
					userUtilize: '',
					dateStart: '',
					dateEnd: '',
					domainRestriction: '',
					productRestrictions: [],
				},
				campaignsList: [],
				discountType: [{
						label: this.$t('sells.coupons.discount-type-f'),
						value: 'F'
					},
					{
						label: this.$t('sells.coupons.discount-type-p'),
						value: 'P'
					},
					// {
					// 	label: 'Shipping',
					// 	value: 'S'
					// }
				],
				productType: [{
						label: this.$t('sells.coupons.product-type-normal'),
						value: 0
					},
					{
						label: this.$t('sells.coupons.product-type-promotion'),
						value: 1
					},
					{
						label: this.$t('sells.coupons.product-type-lichid'),
						value: 2
					},
					{
						label: this.$t('sells.coupons.product-type-resig'),
						value: 3
					},
					{
						label: this.$t('sells.coupons.product-type-refurb'),
						value: 4
					}
				],
				rules: {
					description: [{
						min: 5,
						message: this.$t('sells.coupons.rule-desc'),
						trigger: 'blur'
					}],
					name: [{
							required: true,
							message: this.$t('sells.coupons.rule-name'),
							trigger: 'blur'
						},
						{
							min: 5,
							message: this.$t('sells.coupons.rule-name-2'),
							trigger: 'blur'
						}
					],
					discountType: [{
						required: true,
						message: this.$t('sells.coupons.rule-discount'),
						trigger: 'blur'
					}],
					value: [{
							required: true,
							message: this.$t('sells.coupons.rule-value'),
							trigger: 'blur'
						},
						{
							type: 'number',
							message: this.$t('sells.coupons.rule-value-2'),
							trigger: 'blur'
						}
					],
					minOrder: [{
							required: true,
							message: this.$t('sells.coupons.rule-order'),
							trigger: 'blur'
						},
						{
							type: 'number',
							message: this.$t('sells.coupons.rule-order-2'),
							trigger: 'blur'
						}
					],
					totalUtilize: [{
							required: true,
							message: this.$t('sells.coupons.rule-total'),
							trigger: 'blur'
						},
						{
							type: 'number',
							message: this.$t('sells.coupons.rule-total-2'),
							trigger: 'blur'
						}
					],
					userUtilize: [{
							required: true,
							message: this.$t('sells.coupons.rule-user'),
							trigger: 'blur'
						},
						{
							type: 'number',
							message: this.$t('sells.coupons.rule-user-2'),
							trigger: 'blur'
						}
					],
					dateStart: [{
						required: true,
						message: this.$t('sells.coupons.rule-date-start'),
						trigger: 'blur'

					}],
					dateEnd: [{
						required: true,
						message: this.$t('sells.coupons.rule-date-end'),
						trigger: 'blur'

					}],
					domainRestriction: [{
						required: false,
						message: this.$t('sells.coupons.rule-domain'),
						trigger: 'blur'

					}],
					productRestrictions: [{
						required: false
					}]
				},
				coupon: {
					min: 0,
					max: 999999,
					type: 'RON'
				}
			};
		},
		methods: {
			olderThanToday(exp) {
				return Date.parse(exp) - Date.parse(new Date()) < 0 ? true : false;
			},
			toggleCampaignStatus(campaignId) {
				const currentStatus = this.campaignsList.filter((item) => parseInt(item.id) === campaignId)[0].active ===
					'Y' ? 1 : 0;
				toggleCampaignStatus({
					campaignId: campaignId,
					status: currentStatus
				}).then((res) => {
					if (!has(res, 'message') || !has(res.message, 'success')) {
						this.sbMsg({
							type: 'warn',
							message: this.$t('sells.coupons.error-toggle'),
						});
						return false;
					}
				});
				return true;
			},
			saveCampaign() {
				this.$refs['campaign'].validate((valid) => {
					if (valid) {
						saveCouponsCampaign(this.campaign).then((res) => {
							if (typeof res.message.status !== 'undefined' && parseInt(res.message
									.status) === 1) {
								this.sbMsg({
									type: 'success',
									message: this.$t('sells.coupons.campaign-added'),
								});
								this.getCouponCampaigns();
								this.resetCampaignInputs();
							}
						});
					} else {
						return false;
					}
				});
			},
			resetCampaignInputs() {
				this.campaign = {
					name: '',
					description: '',
					discountType: '',
					value: '',
					minOrder: '',
					totalUtilize: '',
					userUtilize: '',
					dateStart: '',
					dateEnd: '',
					domainRestriction: '',
					productRestrictions: [],
				};
			},
			getCouponCampaigns() {
				getCouponCampaigns().then((res) => {
					if (typeof res.message === 'undefined') return false;
					this.campaignsList = res.message;
				});
			},
			discountTypeDisplay(type) {
				return this.discountType.filter((item) => {
					return item.value === type;
				})[0].label;
			},
			statusDisplay(exp) {
				return Date.parse(exp) - Date.parse(new Date()) < 0 ? this.$t('sells.coupons.expired') : this.$t(
					'sells.coupons.valid');
			},
			changeCouponType(val) {
				switch (val) {
					case 'P':
						this.coupon = {
							min: 0,
							max: 100,
							type: '%'
						};
						break;
					case 'F':
					case 'S':
					default:
						this.coupon = {
							min: 1,
							max: 999999,
							type: 'RON'
						};
				}
			}
		},
		computed: {

		},
		watch: {},
		mounted() {
			this.getCouponCampaigns();
		}
	};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.campaign-add-card {
		.el-form-item {
			margin-bottom: 15px;
		}

		.sub-title {
			margin-bottom: 5px;
		}

		.el-form-item__content {
			padding-bottom: 12px !important;
		}
	}
</style>